import { Button, ListItem } from '@mui/material';
import styled from 'styled-components';

export const AppSidebarItem = styled(ListItem)`
  display: block;
  padding: 0;
  margin: 0;

  &.depth-2 {
    position: relative;

    li:first-child:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 4px;
      background-color: white;
    }

    li:last-child:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 36px;
      height: 4px;
      background-color: white;
    }
  }
`;

export const AppSidebarButton = styled(Button)`
  margin: 0;
  padding: 8px 12px 8px 12px;
  justify-content: flex-start;
  text-transform: none;
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  width: 100%;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.textAction};

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: ${({ theme }) => theme.palette.textAction};
    fill: ${({ theme }) => theme.palette.textAction};
    stroke: ${({ theme }) => theme.palette.textAction};

    &.expand-icon {
      color: ${({ theme }) => theme.palette.textCaption};
      fill: ${({ theme }) => theme.palette.textCaption};
      stroke: ${({ theme }) => theme.palette.textCaption};
    }
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    filter: var(--text-brand-filter);
  }

  &.active {
    color: ${({ theme }) => theme.palette.neutral[700]};

    svg {
      color: ${({ theme }) => theme.palette.neutral[700]};
      fill: ${({ theme }) => theme.palette.neutral[700]};
      stroke: ${({ theme }) => theme.palette.neutral[700]};

      &.expand-icon {
        color: ${({ theme }) => theme.palette.textAction};
        fill: ${({ theme }) => theme.palette.textAction};
        stroke: ${({ theme }) => theme.palette.textAction};
      }
    }

    img {
      filter: initial;
    }

    &.leaf img {
      // Convert to white
      filter: brightness(0) invert(1);
    }
  }

  &.active,
  &:hover {
    background: #f8f8f8;
  }

  &.leaf {
    &.active {
      background-color: ${({ theme }) => theme.palette.textAction};
      color: white;

      svg {
        color: white;
        fill: white;
        stroke: white;
      }
    }
  }
`;

export const SidebarExpandIcon = styled.div`
  display: flex;
  margin-left: auto;

  .expand-icon {
    height: 16px;
    width: 16px;
    opacity: 0.6;
    transform: rotate(180deg);
    position: relative;
    right: -10px;
  }

  .rotate {
    opacity: 0.9;
    transform: rotate(90deg);
  }
`;

export const AppSidebarItemSeparator = styled.div`
  margin: 8px 0px 8px 0px;
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.borderDefault};

  &:last-child {
    display: none;
  }
`;

export const AppSidebarSubDepth = styled.div`
  margin-left: 20px;
  border-left: 1px solid #e0e2e8;
  padding-left: 16px;
  margin-right: 16px;
`;
