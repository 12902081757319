import axiosConfig from './config/axiosConfig';
import { EXECUTE_SCRIPTS_API } from './config/constants';

export default function executeScriptsApi() {
  return {
    async executeScript(params) {
      const query = await axiosConfig.post(`${EXECUTE_SCRIPTS_API}/execute-script`, params);
      return query;
    }
  };
}
