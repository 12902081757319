import { Paper, SwipeableDrawer } from '@mui/material';
import styled from 'styled-components';

export const AppSidebarWrapper = styled(Paper)`
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 60;
  position: fixed;
  width: 264px;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  border-right: 1px solid ${({ theme }) => theme.palette.borderDefault};

  .depth-0 > div > svg {
    width: 18px;
    height: 18px;
  }
`;

export const SwipeableDrawerWrapper = styled(SwipeableDrawer)`
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  width: 264px;
`;
