import axios from 'axios';

import { createBrowserHistory } from 'history';

import { LOGIN_PATH } from 'eficia/routes/paths';

const instance = axios.create({
  // injecter le cookie à chaque requête
  withCredentials: true
});

instance.interceptors.response.use(
  function (response) {
    // TODO: Formatter ici le retour d'api
    return response;
  },
  function (error) {
    const { response } = error;
    // Si l'utilisateur n'est pas connecté (401) on le redirige sur le login.
    if (response?.status === 401) {
      createBrowserHistory().push(`#${LOGIN_PATH}`);
      window.location.reload();
    }

    // retourner l'erreur
    return Promise.reject(error);
  }
);

export default instance;
