import axiosConfig from './config/axiosConfig';
import { SITES_API } from './config/constants';

export default function sitesApi() {
  return {
    async fetch(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/get-site-list`,
        params
        // config
      );
      return query;
    },
    async fetchFull() {
      const query = await axiosConfig.post(`${SITES_API}/get-site-full-list`);
      return query;
    },
    async fetchOne(siteId) {
      const query = await axiosConfig.post(`${SITES_API}/get-site`, siteId);
      return query;
    },
    async edit(params) {
      const query = await axiosConfig.post(`${SITES_API}/edit-site`, params);
      return query;
    },
    async massEdit(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(`${SITES_API}/upload-site-list-file`, fd, config);

      return query;
    },
    async getSiteListFile(params) {
      const query = await axiosConfig.post(`${SITES_API}/export-site-to-update-list-file`, params, {
        responseType: 'blob'
      });
      return query;
    },
    async fetchOrganizationFullListWithSites(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/get-organization-full-list-with-sites`,
        params
      );
      return query;
    }
  };
}
