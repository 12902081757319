import executeScriptsApi from './executeScripts';
import gasMetersApi from './gasmeters';
import groupsApi from './groups';
import organizationsApi from './organizations';
import personalizedPagesApi from './personalizedPages';
import sitesApi from './sites';
import translationApi from './translation';
import userApi from './user';
import usersApi from './users';

export default function eficiaClient() {
  return {
    users: usersApi(),
    sites: sitesApi(),
    organizations: organizationsApi(),
    groups: groupsApi(),
    user: userApi(),
    gasmeters: gasMetersApi(),
    translation: translationApi(),
    personalizedPages: personalizedPagesApi(),
    executeScripts: executeScriptsApi()
  };
}
