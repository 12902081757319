import React, { useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';

import {
  AppContent,
  AppContentInner,
  AppContentWrapper,
  AppMain,
  AppWrapper
} from './LeftSidebar.style';

function LeftSidebar({ children }) {
  const withoutVisibleSidebar = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [sidebarToggleMobile, setSidebarToggleMobile] = useState(false);
  const [hideSidebarContent, setHideSidebarContent] = useState(false);

  const { setIsSidebarPresent } = useContext(LayoutContext);

  useEffect(() => {
    setIsSidebarPresent(true);
    return () => {
      setIsSidebarPresent(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      <Header
        sidebarToggleMobile={sidebarToggleMobile}
        setSidebarToggleMobile={setSidebarToggleMobile}
      />
      <AppMain>
        <Sidebar
          sidebarToggleMobile={sidebarToggleMobile}
          setSidebarToggleMobile={setSidebarToggleMobile}
          hideSidebarContent={hideSidebarContent}
        />
        <AppContent>
          <AppContentInner>
            <AppContentWrapper
              style={{
                marginLeft: withoutVisibleSidebar ? 0 : 264,
                width: withoutVisibleSidebar ? '100vw' : '100%'
              }}
            >
              {children}
            </AppContentWrapper>
          </AppContentInner>
          <Footer />
        </AppContent>
      </AppMain>
    </AppWrapper>
  );
}

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default LeftSidebar;
